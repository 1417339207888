<template>
  <div class="ListView">
    <ListCmp />
  </div>
</template>

<script>
// @ is an alias to /src
import ListCmp from '@/components/ListCmp.vue'

export default {
  name: 'ListView',
  components: {
    ListCmp
  }
}
</script>
