<template>
  <div class="LdFilterView">
    <LdFilterCmp />
  </div>
</template>

<script>
// @ is an alias to /src
import LdFilterCmp from '@/components/LdFilterCmp.vue'

export default {
  name: 'LdFilterView',
  components: {
    LdFilterCmp
  }
}
</script>
