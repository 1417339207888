<template>
  <div id="list-cmp" class="listCmp">
    <b-modal
      :id="exampleModal.id"
      :title="exampleModal.title"
      size="xl"
      @hide="resetExampleModal"
    >
      <b-embed
        aspect="16by9"
        :src="exampleModal.src"
        allowfullscreen
        style="width: 100% !important"
      >
        <p>O seu browser não suporta a visualização desse PDF.</p>
      </b-embed>

      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="danger" @click="ok()"> OK </b-button>
      </template>
    </b-modal>

    <Pulse-Loader
      :loading="loading"
      style="
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100vh;
        margin-top: 25%;
      "
    ></Pulse-Loader>

    <router-link
      v-if="this.state.userType !== 'Paciente'"
      to="/mdy-filter"
      exact
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="back"
        viewBox="0 0 172 172"
        style="fill: #000000"
      >
        <g
          fill="none"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style="mix-blend-mode: normal"
        >
          <path d="M0,172v-172h172v172z" fill="none"></path>
          <g fill="#049c64">
            <path
              d="M120.26563,10.25281c-1.78719,0.05375 -3.48031,0.80625 -4.73,2.08281l-68.8,68.8c-2.6875,2.6875 -2.6875,7.04125 0,9.72875l68.8,68.8c1.72,1.80062 4.28656,2.52625 6.70531,1.89469c2.40531,-0.63156 4.28656,-2.51281 4.91813,-4.91813c0.63156,-2.41875 -0.09406,-4.98531 -1.89469,-6.70531l-63.93563,-63.93563l63.93563,-63.93562c2.02906,-1.97531 2.64719,-4.99875 1.54531,-7.61906c-1.11531,-2.60688 -3.70875,-4.27313 -6.54406,-4.1925z"
            ></path>
          </g>
        </g>
      </svg>
    </router-link>
    <label v-on:click="logout">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="logout list"
        viewBox="0 0 172 172"
        style="fill: #000000"
      >
        <g
          fill="none"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style="mix-blend-mode: normal"
        >
          <path d="M0,172v-172h172v172z" fill="none"></path>
          <g fill="#4a923e">
            <path
              d="M41.20833,21.5c-10.82086,0 -19.70833,8.88748 -19.70833,19.70833v89.58333c0,10.82086 8.88748,19.70833 19.70833,19.70833h64.5c10.82086,0 19.70833,-8.88748 19.70833,-19.70833c0.02741,-1.93842 -0.99102,-3.74144 -2.66532,-4.71865c-1.6743,-0.97721 -3.74507,-0.97721 -5.41937,0c-1.6743,0.97721 -2.69273,2.78023 -2.66532,4.71865c0,5.01031 -3.94802,8.95833 -8.95833,8.95833h-64.5c-5.01031,0 -8.95833,-3.94802 -8.95833,-8.95833v-89.58333c0,-5.01031 3.94802,-8.95833 8.95833,-8.95833h64.5c5.01031,0 8.95833,3.94802 8.95833,8.95833c-0.02741,1.93842 0.99102,3.74144 2.66532,4.71865c1.6743,0.97721 3.74507,0.97721 5.41937,0c1.6743,-0.97721 2.69273,-2.78023 2.66532,-4.71865c0,-10.82086 -8.88748,-19.70833 -19.70833,-19.70833zM119.98568,55.48568c-2.18814,0.00053 -4.1576,1.32735 -4.98006,3.35504c-0.82245,2.0277 -0.33375,4.35156 1.23575,5.87624l15.90804,15.90804h-76.60775c-1.93842,-0.02741 -3.74144,0.99102 -4.71865,2.66532c-0.97721,1.6743 -0.97721,3.74507 0,5.41937c0.97721,1.6743 2.78023,2.69273 4.71865,2.66532h76.60775l-15.90804,15.90804c-1.40412,1.34815 -1.96971,3.35005 -1.47866,5.23364c0.49105,1.88359 1.96202,3.35456 3.84561,3.84561c1.88359,0.49105 3.88549,-0.07455 5.23364,-1.47866l25.08333,-25.08333c2.09823,-2.0991 2.09823,-5.50149 0,-7.60059l-25.08333,-25.08333c-1.01222,-1.0424 -2.4033,-1.63064 -3.85628,-1.6307z"
            ></path>
          </g>
        </g>
      </svg>
    </label>
    <div class="wp fadeInList">
      <div>
        <div class="organiza">
          <div ref="legendas" class="legendas">
            <div class="fadeIn first">
              <img src="img/logo_unimed_sta_rosa.jpg" id="icon" alt="Lab Logo" />
            </div>
            <hr />
            <div class="status">
              <div>
                <label style="color: #f5c6cb"> Em Andamento </label>
                |
                <label style="color: #0066cc"> Parcialmente Liberado </label>
                |
                <label style="color: #339933"> Totalmente Liberado </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="organizacao">
          <div ref="laudos" class="laudo">
            <input
              class="barraPesquisa"
              id="campoPesquisa"
              type="text"
              placeholder="Procurar: Data, Paciente ou Laudo."
              v-on:keyup="pesquisar"
              ref="inpSearch"
            />
            <div
              class="tabela"
              style="position: relative; height: 472px; overflow: auto"
            >
              <b-table
                id="laudos-table"
                stacked="md"
                ref="laudos-table"
                small
                selectable
                :items="laudos"
                :fields="fields"
                @row-clicked="showPdf"
              >
                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    @click="info(row.item, row.index, $event.target)"
                  >
                    Exames
                  </b-button>
                  |
                  <b-button size="sm" @click="showPdf(row.item, row.index)">
                    <font-awesome-icon icon="fa-regular fa-file-lines" />
                  </b-button>
                </template>
              </b-table>
              <!-- Info modal -->
              <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                @hide="resetInfoModal"
              >
                <b-table
                  id="ems-table"
                  stacked="md"
                  ref="ems-table"
                  small
                  selectable
                  :items="ems"
                >
                </b-table>
                <!--<b-button
                  class="mt-2"
                  variant="outline-warning"
                  block
                  @click="nextReq(infoModal.content)"
                  >-></b-button
                  >-->

                <template #modal-footer="{ ok }">
                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="showPdf(itemsG)"
                  >
                    Ver Laudo
                  </b-button>
                  <b-button size="sm" variant="danger" @click="ok()">
                    OK
                  </b-button>
                </template>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { BModal } from "bootstrap-vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "ListCmp",
  components: {
    btable: BTable,
    bmodal: BModal,
    PulseLoader,
  },
  data() {
    return {
      loading: false,
      currentRIndex: null,
      btInfo: null,
      ems: [],
      itemsG: [],
      fields_ems: [
        {
          key: "emsDesc",
          label: "Descricao",
          sortable: true,
        },
        {
          key: "emsStatus",
          label: "Status",
          sortable: true,
        },
      ],
      fields: [
        {
          key: "dtAtend",
          label: "Dt.Atend.",
          sortable: true,
        },
        {
          key: "dtPrentAtend",
          label: "Prev.Liberação",
          sortable: true,
          isRowHeader: false,
        },
        {
          key: "pacNome",
          label: "Paciente",
          sortable: false,
        },
        {
          key: "atend",
          label: "Nr.Atend",
          sortable: false,
        },
        {
          key: "medNome",
          label: "Solicitante",
          sortable: false,
          isRowHeader: false,
        },
        /*{
          key: "idMedReq",
          label: "M.Req",
          sortable: false,
        },*/
        { key: "actions", label: "Ações", sortable: false },
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      exampleModal: {
        id: "exampleModal",
        titlte: "",
        src: "",
      },
    };
  },
  mounted: function () {
    this.checkMobile();
    this.checkLoginPat();
  },
  computed: {
    state() {
      return this.$store.state;
    },
    laudos() {
      return this.$store.laudos;
    },
    acoes() {
      return this.$actions;
    },
  },
  methods: {
    // NOTE(pac): Revisar funcao next interna do modal
    nextReq(item) {
      let _this = this;
      this.$root.$emit("bv::hide::modal", this.infoModal.id, _this.btInfo);
      _this.info(item, _this.currentRIndex + 1, _this.btInfo);
    },
    async showPdf(items, index) {
      let _this = this;
      _this.currentRIndex = index;
      this.loading = true;
      this.selected = items;

      if (document.getElementById("info-modal"))
        document.getElementById("info-modal").style.zIndex = 0;
      document.getElementsByClassName("v-spinner")[0].style.zIndex = 10550;

      axios
        .get(
          "/v1/report" +
            "?COD103=" +
            items.atend.split(".")[0] + // idatend
            "&COD104=" +
            items.atend.split(".")[1].split("/")[0] + // idfilialatn
            "&COD102=" +
            "20" +
            items.atend.split(".")[1].split("/")[1] + // atnano
            "&COD105=" +
            items.req + // idmedreq
            "&COD106=" +
            _this.state.lType + // tipo usuario
            "&COD101=" +
            _this.state.user +
            "&COD100=" +
            _this.state.password
        )
        .then(function (response) {
          if (response.status === 200) {
            var pdf = response.data.msg.$value;
            try {
              if (pdf === "" || pdf === undefined) {
                _this.$notify({
                  group: "alerts",
                  type: "warn",
                  title: "ATENCAO",
                  text: "Em Andamento, ainda não há resultados liberados para consulta!",
                });
              } else {
                var isSafari =
                  navigator.vendor &&
                  navigator.vendor.indexOf("Apple") > -1 &&
                  navigator.userAgent &&
                  navigator.userAgent.indexOf("CriOS") == -1 &&
                  navigator.userAgent.indexOf("FxiOS") == -1;
                if (isSafari) {
                  const binary = atob(pdf.replace(/\s/g, ""));
                  const len = binary.length;
                  const buffer = new ArrayBuffer(len);
                  const view = new Uint8Array(buffer);

                  for (let i = 0; i < len; i += 1) {
                    view[i] = binary.charCodeAt(i);
                  }

                  // create the blob object with content-type "application/pdf"
                  const blob = new Blob([view], { type: "application/pdf" });
                  const url = URL.createObjectURL(blob);

                  const a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  a.href = url;
                  a.download = "Test.pdf";
                  a.target = "_blank";
                  a.click();
                } else {
                  const binary = atob(pdf.replace(/\s/g, ""));
                  const len = binary.length;
                  const buffer = new ArrayBuffer(len);
                  const view = new Uint8Array(buffer);

                  for (let i = 0; i < len; i += 1) {
                    view[i] = binary.charCodeAt(i);
                  }

                  // create the blob object with content-type "application/pdf"
                  const blob = new Blob([view], { type: "application/pdf" });
                  const url = URL.createObjectURL(blob);

                  const a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  a.href = url;
                  a.download = items.atend.split(".")[0] + ".pdf";
                  a.target = "_blank";
                  a.click();
                }
              }
            } catch (error) {
              console.log("Error", error);
            }
          } else {
            console.log("else: ", response);
          }
        })
        .finally(() => {
          this.loading = false;
          if (document.getElementById("info-modal"))
            document.getElementById("info-modal").style.zIndex = 1055;
          document.getElementsByClassName("v-spinner")[0].style.zIndex = 100;
        });
    },
    async info(item, index, button) {
      let _this = this;
      _this.currentRIndex = index;
      _this.btInfo = button;
      _this.ems = [];
      _this.itemsG = item;

      let username = _this.state.user;
      let password = _this.state.password;
      let tpLogin = _this.state.lType;
      let nrLaudo = item.atend;
      let idFilial = item.atend.split(".")[1].split("/")[0];
      let idMedReq = item.idMedReq;

      let llEms = [];
      await axios
        .get(
          "/v1/atends-ld" +
            "?COD104=" +
            idFilial +
            "&COD106=" +
            tpLogin +
            "&COD101=" +
            username +
            "&COD100=" +
            password +
            "&COD130=" +
            item.pacNome +
            "&COD131=" +
            nrLaudo
        )
        .then(function (response) {
          if (response.status === 200) {
            var xml = response.data.msg.Resultado.$value;
            const parser = new DOMParser();
            const doc = parser.parseFromString(xml, "application/xml");
            const errorNode = doc.querySelector("parsererror");
            if (errorNode) {
              console.log("error while parsing");
            } else {
              let lstAtends = doc.all[0].children;
              let lEmsStatus = "";
              lstAtends = [...lstAtends];
              lstAtends.forEach((e, index) => {
                if (e.children[3].textContent === idMedReq.toString()) {
                  var lstExams = e.getElementsByTagName("listaExames");
                  lstExams = [...lstExams];
                  lstExams.forEach((e, index) => {
                    var exams = e.children;
                    exams = [...exams];
                    exams.forEach((e, index) => {
                      if (
                        e.children[2].textContent.replace("tse", "") ===
                        "Nenhum"
                      )
                        lEmsStatus = e.children[2].textContent = "Em Andamento";
                      else
                        lEmsStatus = e.children[2].textContent.replace(
                          "tse",
                          ""
                        );
                      _this.ems.push({
                        Descricao: e.children[1].textContent,
                        Status: lEmsStatus,
                      });
                    });
                  });
                }
              });
            }
          } else {
            console.log("else: ", response);
          }
        })
        .finally(() => {
          this.loading = false;
          _this.infoModal.title = item.pacNome;
          _this.infoModal.content = JSON.stringify(llEms, null, 2);
          _this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        });
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    resetExampleModal() {},
    logout() {
      // TODO(pac): Uma vez com o token no localstorage,limpar ao executar essa funcao
      let _this = this;
      _this.acoes.setToken(_this.state);
      _this.$router.push("/");
    },
    checkLoginPat() {
      let _this = this;
      if (_this.state.lType == "tulPaciente") _this.listPatAtends();
      if (_this.state.lType === "tulPacienteCpf") _this.listPatCpfAtends();
    },
    checkMobile() {
      let w = window.innerWidth;
      if (w < 799) {
        this.$refs.legendas.style.display = "none";
        this.$refs.laudos.style.marginTop = "0px";
      } else {
        this.$refs.legendas.style.display = "block";
      }
    },
    async showLstAtend(id) {
      let _this = this;
      let pdf = "";
      let idMedReq = id;
      var username = _this.state.user;
      var filial = _this.state.user.split(".")[1].split("/")[0];
      var password = _this.state.password;
      var tpLogin = _this.state.lType;

      await axios
        .get(
          "/v1/report" +
            "?COD103=" +
            username.split(".")[0] + // idatend
            "&COD104=" +
            filial + // idfilialatn
            "&COD102=" +
            "20" +
            username.split(".")[1].split("/")[1] + // atnano
            "&COD105=" +
            idMedReq + // idmedreq
            "&COD106=" +
            tpLogin + // tipo usuario
            "&COD101=" +
            username +
            "&COD100=" +
            password
        )
        .then(function (response) {
          if (response.status === 200) {
            pdf = response.data.msg.$value;
            var a = document.createElement("a");
            a.href = "data:application/octet-stream;base64," + pdf;
            a.target = "_blank";
            a.download = "_.pdf";
            a.click();
          } else {
            console.log("else: ", response);
          }
        });
    },
    async listPatCpfAtends() {
      let _this = this;
      let ac = _this.acoes;
      let idMedReq = null;
      var username = _this.state.user;
      var filial = null;
      var password = _this.state.password;
      var tpLogin = _this.state.lType;

      let llEms = [];
      await axios
        .get(
          "/v1/atends" +
            "?COD104=" +
            filial +
            "&COD106=" +
            tpLogin +
            "&COD101=" +
            username +
            "&COD100=" +
            password
        )
        .then(function (response) {
          if (response.status === 200) {
            var xml = response.data.msg.Resultado.$value;
            const parser = new DOMParser();
            const doc = parser.parseFromString(xml, "application/xml");
            const errorNode = doc.querySelector("parsererror");
            if (errorNode) {
              console.log("error while parsing");
            } else {
              var listAtends = doc.all[0].children;
              var l = [];
              for (const el of listAtends) {
                var idAtend = el.getElementsByTagName("idAtend")[0].textContent;
                var idFilial =
                  el.getElementsByTagName("idFilial")[0].textContent;
                var atnAno = el.getElementsByTagName("atnAno")[0].textContent;
                var idMedReq =
                  el.getElementsByTagName("idMedReq")[0].textContent;
                var medAbr = el.getElementsByTagName("medAbr")[0].textContent;
                var medNome = el.getElementsByTagName("medNome")[0].textContent;
                var pacNome = el.getElementsByTagName("pacNome")[0].textContent;
                var atnIdent =
                  el.getElementsByTagName("atnIdent")[0].textContent;
                var dtAtend = el
                  .getElementsByTagName("atnDtAtend")[0]
                  .textContent.split(" ")[0];
		if(el.getElementsByTagName("listaExames").length < 0)
			continue;

		var lstEms = el.getElementsByTagName("listaExames")[0].children;
                
                var atend = idAtend + "." + idFilial + "/" + atnAno.slice(2, 4);
                var resLib = -1;
                var resLibStr = "";
                var estatus = -1;
                let lEmsStatus = "";
                var _rowVar = "primary";

                for (const ems of lstEms) {
                  lEmsStatus = ems
                    .getElementsByTagName("emsStatus")[0]
                    .textContent.replace("tse", "");
                  if (lEmsStatus === "Liberado") resLibStr = resLibStr + "0";
                  else resLibStr = resLibStr + "1";
                }

                if (resLibStr.includes("0")) {
                  estatus = 0;
                  resLib = 0;
                }
                if (resLibStr.includes("1")) {
                  if (estatus === 0) resLib = 1;
                  else resLib = 0;
                } else {
                  resLib = 2; // totalmente liberado
                }

                if (resLib == 0) _rowVar = "danger";
                if (resLib == 1) _rowVar = "primary";
                if (resLib == 2) _rowVar = "success";
                var lObj = {
                  dtAtend: dtAtend,
                  atend: atend,
                  pacNome: pacNome,
                  medNome: medAbr + " " + medNome,
                  idMedReq: idMedReq,
                  req: idMedReq,
                  status: resLib,
                  _rowVariant: _rowVar,
                };
                l.push(lObj);
              }
              ac.setLaudos(l);
            }
          } else {
            console.log("else: ", response);
          }
        });
    },
    async listPatAtends() {
      let _this = this;
      let ac = _this.acoes;
      let idMedReq = null;
      var username = _this.state.user;
      var filial = _this.state.user.split(".")[1].split("/")[0];
      var password = _this.state.password;
      var tpLogin = _this.state.lType;
      let llEms = [];

      await axios
        .get(
          "/v1/atends" +
            "?COD104=" +
            filial +
            "&COD106=" +
            tpLogin +
            "&COD101=" +
            username +
            "&COD100=" +
            password
        )
        .then(function (response) {
          if (response.status === 200) {
            var xml = response.data.msg.Resultado.$value;
            const parser = new DOMParser();
            const doc = parser.parseFromString(xml, "application/xml");
            const errorNode = doc.querySelector("parsererror");
            if (errorNode) {
              console.log("error while parsing");
            } else {
              var listAtends = doc.all[0].children;
              var l = [];
              for (const el of listAtends) {
                var idAtend = el.getElementsByTagName("idAtend")[0].textContent;
                var idFilial =
                  el.getElementsByTagName("idFilial")[0].textContent;
                var atnAno = el.getElementsByTagName("atnAno")[0].textContent;
                var idMedReq =
                  el.getElementsByTagName("idMedReq")[0].textContent;
                var medAbr = el.getElementsByTagName("medAbr")[0].textContent;
                var medNome = el.getElementsByTagName("medNome")[0].textContent;
                var pacNome = el.getElementsByTagName("pacNome")[0].textContent;
                var atnIdent =
                  el.getElementsByTagName("atnIdent")[0].textContent;
                var dtAtend = el
                  .getElementsByTagName("atnDtAtend")[0]
                  .textContent.split(" ")[0];
		var lstEms = null;
		if(!el.getElementsByTagName("listaExames")){
			return;
		} 
                lstEms = el.getElementsByTagName("listaExames")[0].children;
                var atend = idAtend + "." + idFilial + "/" + atnAno.slice(2, 4);
                var resLib = -1;
                var resLibStr = "";
                var estatus = -1;
                let lEmsStatus = "";
                var _rowVar = "primary";
                for (const ems of lstEms) {
                  lEmsStatus = ems
                    .getElementsByTagName("emsStatus")[0]
                    .textContent.replace("tse", "");
                  if (lEmsStatus === "Liberado") resLibStr = resLibStr + "0";
                  else resLibStr = resLibStr + "1";
                }
                if (resLibStr.includes("0")) {
                  estatus = 0;
                  resLib = 0;
                }
                if (resLibStr.includes("1")) {
                  if (estatus === 0) resLib = 1;
                  else resLib = 0;
                } else {
                  resLib = 2; // totalmente liberado
                }
                if (resLib == 0) _rowVar = "danger";
                if (resLib == 1) _rowVar = "primary";
                if (resLib == 2) _rowVar = "success";
                var lObj = {
                  dtAtend: dtAtend,
                  atend: atend,
                  pacNome: pacNome,
                  idMedReq: idMedReq,
                  medNome: medAbr + " " + medNome,
                  req: idMedReq,
                  status: resLib,
                  _rowVariant: _rowVar,
                };
                l.push(lObj);
              }
              ac.setLaudos(l);
            }
          } else {
            console.log("else: ", response);
          }
        });
    },
    setLoading(v) {
      let _this = this;
      _this.loading = v;
    },
    async onRowSelected(items) {
      let _this = this;
      this.loading = true;
      this.selected = items;

      if (document.getElementById("info-modal"))
        document.getElementById("info-modal").style.zIndex = 0;
      document.getElementsByClassName("v-spinner")[0].style.zIndex = 10550;

      axios
        .get(
          "/v1/report" +
            "?COD103=" +
            items.atend.split(".")[0] + // idatend
            "&COD104=" +
            items.atend.split(".")[1].split("/")[0] + // idfilialatn
            "&COD102=" +
            "20" +
            items.atend.split(".")[1].split("/")[1] + // atnano
            "&COD105=" +
            items.req + // idmedreq
            "&COD106=" +
            _this.state.lType + // tipo usuario
            "&COD101=" +
            _this.state.user +
            "&COD100=" +
            _this.state.password
        )
        .then(function (response) {
          if (response.status === 200) {
            var pdf = response.data.msg.$value;
            try {
              if (pdf === "" || pdf === undefined) {
                _this.$notify({
                  group: "alerts",
                  type: "warn",
                  title: "ATENCAO",
                  text: "Em Andamento, ainda não há resultados liberados para consulta!",
                });
              } else {
                /*var a = document.createElement("a");
                a.href = "data:application/octet-stream;base64," + pdf;
                a.target = '_blank';
                a.download = '_.pdf';
                a.click();*/
              }
            } catch (error) {
              console.log("Error", error);
            }
          } else {
            console.log("else: ", response);
          }
        })
        .finally(() => {
          this.loading = false;
          if (document.getElementById("info-modal"))
            document.getElementById("info-modal").style.zIndex = 1055;
          document.getElementsByClassName("v-spinner")[0].style.zIndex = 100;
        });
    },
    pesquisar() {
      var value = this.$refs.inpSearch.value.toLowerCase();
      $("#laudos-table tbody tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
iframe {
  width: 100% !important;
}
</style>
